import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  process,
  State,
  SortDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { projectData } from './project-data';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import {
  allPayloadResponse,
  getResponseConfigResponse,
} from '../../../store/payload_Config/payloadConfig-selector';
import {
  deleteResponseConfigAction,
  getAllpayloadConfig,
} from '../../../store/payload_Config/payloadConfig.actions';
import { NavigationEnd, Router } from '@angular/router';
import { CdkStepper } from '@angular/cdk/stepper';
import { filter, pairwise } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { GetResponseConfig } from '../../../store/payload_Config/payloadConfig.actions';
import {
  createPayloadResponse,
  deleteResponseConfig,
} from '../../../store/payload_Config/payloadConfig-selector';
import {
  ConfirmationModal,
  table_pageSize,
} from '../../../shared/modals/common';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
@Component({
  selector: 'app-iot-configuration-view',
  templateUrl: './iot-configuration-view.component.html',
  styleUrl: './iot-configuration-view.component.scss',
})
export class IotConfigurationViewComponent implements OnInit {
  selectValue!: string[];
  hidden!: boolean;
  selected: any;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @Input() fromDate!: Date;
  @Input() toDate!: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

  @ViewChild('dp', { static: true }) datePicker: any;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public gridData: unknown[] = projectData;
  public gridView!: unknown[];
  responseConfigList: any = [];
  pageSize: any;
  responseConfigPageSize: number = 10;
  totalResponseConfigCount: any;
  public sort: SortDescriptor[] = [];
  public skip = 0;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public responseConfigGridData: GridDataResult = { data: [], total: 0 };
  selectableSettings = this.sharedService.selectableSettings;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public payloadConfigState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  breadCrumbItems: Array<{}> | undefined;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  payloadConfig_QueryString: string = '';
  payloadConfigCurrentPage: number = 1;
  searchedPayloadConfigValue: any = '';
  payloadConfigOrderByQuery: string = '';
  mySelectionPayloadConfig: any = [];
  @ViewChild('cdkStepper') cdkStepper!: CdkStepper;
  // @ViewChild('excelexport', { static: true })
  // excelExport!: ExcelExportComponent;
  @ViewChild('excelexport') public excelExport: GridComponent | undefined;
  @ViewChild('payloadconfigGrid') public hiddenpayloadConfigPdfGrid:
    | GridComponent
    | undefined;
  allPayloadConfigData: any = [];
  exportType: string = '';
  fileName: string = '';
  constructor(
    private store: Store,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.breadCrumbItems = [{ label: 'Configurations', url: 'configuration' }];
    this.getStoreInfo();
  }

  getStoreInfo() {
    let getResponseConfigs$ = this.store.pipe(
      select(getResponseConfigResponse)
    );
    let createPayloadResponse$ = this.store.pipe(select(createPayloadResponse));
    let deleteResponseConfigs$ = this.store.pipe(select(deleteResponseConfig));
    let allPayloadResponse$ = this.store.pipe(select(allPayloadResponse));
    getResponseConfigs$.subscribe((res: any) => {
      if (res) {
        this.responseConfigList = [...res.items];
        this.responseConfigPageSize = res.pageSize;
        this.totalResponseConfigCount = res.totalCount;
      }
      this.loadPayloadConfig();
      console.log('payload', res, this.responseConfigList);
    });
    createPayloadResponse$.subscribe((res: any) => {
      this.getResponseConfigDataList();
      console.log('createPayloadResponse');
    });

    deleteResponseConfigs$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.getResponseConfigDataList();
      }
      this.loadPayloadConfig();
      console.log('payloaddeleteafter', res);
    });

    allPayloadResponse$.subscribe((res: any) => {
      if (res) {
        this.allPayloadConfigData = [...res.items];
      }
      console.log('allpayloaddata', res, this.allPayloadConfigData);
      console.log(
        this.mySelectionPayloadConfig.length > 0
          ? this.mySelectionPayloadConfig
          : this.allPayloadConfigData.length > 0
          ? this.allPayloadConfigData
          : this.responseConfigGridData.data
      );
    });
  }

  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
 


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  public onPayloadConfigFilterChange(filter: CompositeFilterDescriptor): void {
    this.payloadConfigState.filter = filter;
    console.log(this.payloadConfigState);
    const queryString = this.sharedService.createQuery(this.payloadConfigState);
    this.payloadConfig_QueryString = this.sharedService.createQuery(
      this.payloadConfigState
    );
    this.getResponseConfigDataList();
    console.log(queryString);
  }

  callAllPayloadConfigExport() {
    // this.store.dispatch(
    //   GetpayloadConfig({
    //     pageIndex: 1,
    //     pageSize: this.totalResponseConfigCount,
    //     searchTerm: this.searchedPayloadConfigValue,
    //     filter: this.payloadConfig_QueryString,
    //     orderBy: this.payloadConfigOrderByQuery,
    //   })
    // );
  }

  export(type: string) {
    if (this.mySelectionPayloadConfig.length == 0) {
      this.getAllPayloadConfigDataList();
    }
    if (type === 'Excel') {
      this.exportType = type;
      setTimeout(() => {
        this.hiddenpayloadConfigPdfGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenpayloadConfigPdfGrid?.saveAsPDF();
      }, 1000);
    }
  }

  onSortChange(sort: SortDescriptor[], type: string): void {
    this.payloadConfigState.sort = sort;
    console.log(this.payloadConfigState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.payloadConfigState.sort
    );

    this.payloadConfigOrderByQuery = orderByQuery;
    this.getResponseConfigDataList();
  }

  protected payloadConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.responseConfigPageSize = take;
    this.payloadConfigCurrentPage = Math.floor(skip / take) + 1;
    console.log('payloadConfigCurrentPage', Math.floor(skip / take) + 1);
    this.getResponseConfigDataList();
    this.loadPayloadConfig();
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadPayloadConfig();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionPayloadConfig.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionPayloadConfig.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionPayloadConfig.push(row.dataItem);
      }
    });

    console.log(this.mySelectionPayloadConfig);
  }

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionPayloadConfig.some(
      (row: any) => row.id === rowArg.id
    );
  };

  private loadPayloadConfig(): void {
    const state: State = {
      take: this.responseConfigPageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.responseConfigList, state);
    this.responseConfigGridData = {
      data: processedData.data,
      total: this.totalResponseConfigCount,
    };
  }

  public onFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedPayloadConfigValue = value;
    this.payloadConfigPageChange({ skip:0, take:this.responseConfigPageSize })
    // this.getResponseConfigDataList();
  }

  getResponseConfigDataList() {
    this.store.dispatch(
      GetResponseConfig({
        pageIndex: this.payloadConfigCurrentPage,
        pageSize: this.responseConfigPageSize,
        searchTerm: this.searchedPayloadConfigValue,
        filter: this.payloadConfig_QueryString,
        orderBy: this.payloadConfigOrderByQuery,
      })
    );
  }

  getAllPayloadConfigDataList() {
    this.store.dispatch(
      getAllpayloadConfig({
        pageIndex: 1,
        pageSize: this.totalResponseConfigCount,
        searchTerm: this.searchedPayloadConfigValue,
        filter: this.payloadConfig_QueryString,
        orderBy: this.payloadConfigOrderByQuery,
      })
    );
  }

  deleteResponse(dataItem: any): void {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.ERROR,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        this.store.dispatch(deleteResponseConfigAction({ id: dataItem.id }));
        // Perform delete operation here
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sharedService.showMessageDialog(
          'Cancelled',
          '',
          SweetAlertIcon.INFO
        );
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  deletePayload() {}

  ngOnInit(): void {
    this.gridView = this.gridData;
    this.selectValue = [
    ];
    this.selected = '';
    this.hidden = true;
    this.getResponseConfigDataList();
  }

  createPayloadConfig() {
    this.router.navigate(['/configuration/payloadConfiguration'], {
      state: { type: 'Create' },
    });
  }
  createResponseConfig() {
    this.router.navigate(['/configuration/createResponseConfiguration'], {
      state: { type: 'Create' },
    });
  }

  editResponseConfig(item: any) {
    const serializedData = JSON.stringify(item);
    this.router.navigate(['/configuration/createResponseConfiguration'], {
      state: { responseConfigData: serializedData },
    });
  }

  
  public clearSearchValueChangeOnOffData(ev: string): void {
    if (ev.length == 0) {
      this.payloadConfigPageChange({ skip:0, take:this.responseConfigPageSize })
    }
  }

  
  deletePayloadConfig(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          // this.store.dispatch(deletepayloadConfig({ id: dataItem.id }));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  nextStep(stepper: CdkStepper) {
    stepper.next();
  }
}
